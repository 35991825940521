import axios from '../axios'

export default {
  // Создание проекта
  async createProject({ commit }, projectData) {
    try {
      commit('setProjectCreate', true)

      const params = {
        name: projectData.name,
        description: projectData.description || undefined,
        plan_date: projectData.start_date,
        deadline: projectData.end_date,
        teams: projectData.teams?.map(team => team.id),
        is_archive: 0,
      }

      const response = await axios.post('projects', params)

      commit('setNewProject', response)
      localStorage.setItem('currentSelectedProject', JSON.stringify(response))
      commit('Notify/setSuccess', `Проект ${response.name} успешно создан`, { root: true })

      return response
    } catch (error) {
      if (!error.logout) {
        commit('Notify/setError', 'При создании проекта произошла ошибка. Попробуйте позже.', { root: true })
      }
    } finally {
      commit('setProjectCreate', false)
    }
  },
  // Редактирование проекта
  async editProject({ commit }, projectData) {
    try {
      commit('setProjectCreate', true)

      const params = {
        name: projectData?.name,
        description: projectData?.description,
        plan_date: projectData?.start_date,
        deadline: projectData?.end_date,
        teams: projectData?.teams?.map(team => team.id),
      }

      const response = await axios.put(`projects/${projectData.id}`, params)

      commit('changeProject', response)
      commit('Notify/setSuccess', `Проект ${response.name} успешно изменён`, { root: true })
    } catch (error) {
      if (!error.logout) {
        commit('Notify/setError', 'При редактировании проекта произошла ошибка. Попробуйте позже.', { root: true })
      }
    } finally {
      commit('setProjectCreate', false)
    }
  },
  // Получение списка проектов
  async downloadProjectsList({ rootState, commit }, projectParams) {
    try {
      commit('setProjectLoading', true)
      const response = await axios.get('/get_list_project', {
        params: {
          per_page: 1000,
          search: projectParams?.searchProject?.length ? projectParams.searchProject : undefined,
          'filter[is_plan_repair]': projectParams.isPlanRepair === 1 ? 1 : undefined,
          'filter[is_deleted]': projectParams.isTrash === 1 ? 1 : undefined,
          'filter[is_archive]': projectParams.isArchive === 1 ? 1 : undefined,
        },
      })

      const projects = response.data

      commit('setProjectList', projects)

      return projects
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    } finally {
      commit('setProjectLoading', false)
    }
  },
  // Удаление проекта
  async deleteProject({ commit }, projectId) {
    try {
      commit('setDeleteLoading', true)

      await axios.delete(`projects/${projectId}`)

      commit('setDeleteProjectFromSomeList', projectId)
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setDeleteLoading', false)
    }
  },
  // Клонировать проект
  async cloneProject({ commit }, project) {
    try {
      commit('setCloneLoading', true)

      const response = await axios.post(`projects/${project.id}/clone`, project)
      commit('setNewProject', response)

      commit('Notify/setSuccess', 'Проект успешно склонирован', { root: true })
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При клонировании произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setCloneLoading', false)
    }
  },
  // Архивировать проект
  async archiveProject({ commit }, id) {
    const data = new FormData()
    data.set('is_archive', 1)

    try {
      commit('setArchiveLoading', true)

      const response = await axios.put(`projects/${id}`, data)
      console.info('response', response)

      commit('setDeleteProjectFromSomeList', response.id)
    } catch (e) {
      commit('Notify/setError', 'При архивации произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setArchiveLoading', false)
    }
  },
  // Разархивировать проект
  async unArchiveProject({ commit }, id) {
    const data = new FormData()
    data.set('is_archive', 0)

    try {
      commit('setUnArchiveLoading', true)

      const response = await axios.put(`projects/${id}`, data)

      commit('setDeleteProjectFromSomeList', response.id)
    } catch (e) {
      commit('Notify/setError', 'При разархивации произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setUnArchiveLoading', false)
    }
  },
  // Переместить проект из АПР в стандартные проекты
  async moveAprProject({ commit }, id) {
    try {
      commit('setAprProjectMoveLoading', true)

      await axios.post('repairs/move_plan_to_real', {
        repairs: [id],
      })

      // Нужно удалить из списка проектов все проекты, у которых есть такой repair id
      commit('setDeleteAprProjectAfterMove', id)
    } catch (e) {
      commit('Notify/setError', 'При переносе проекта из АПР произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setAprProjectMoveLoading', false)
    }
  },
  // Востановление проекта
  async restoreProject({ commit }, projectId) {
    try {
      await axios.post('restore_project', { projects_ids: [projectId] })
      // Удаляю проект из общего списка, т.к. при открытии проектов он перезапросится. Если он принадлежит к ремонту, то все проекты этого ремонта восстановятся
      commit('setDeleteProjectFromSomeList', projectId)
    } catch (e) {
      commit('Notify/setError', 'При восстановлении проекта произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  // Отправка задач из таблицы импорта
  async importTasks({ commit }, payload) {
    try {
      await axios.post('tasks/import', payload)
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке произошла ошибка', { root: true })
      }
    }
  },
  async importKTD({ commit }, payload) {
    try {
      await axios.post('/parse_ktd', payload)
      commit('Notify/setSuccess', 'Файл успешно отправлен', { root: true })
      const projectId = payload.get('project_id')
      const data = {
        project_id: projectId,
        needStub: true,
      }
      commit('setStubForImportKtd', data)
      return true
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке произошла ошибка', { root: true })
      }
    }
  },
  async downloadProjectTeamList({ commit }, projectId) {
    try {
      const response = await axios.put(`/projects/${projectId}`)
      // TODO: [@asiuraev 26.09.2024] Нужно использовать отдельный запрос на получение команд
      return response?.teams.map(team => {
        return {
          display_name: team.display_name,
          id: team.id,
          name: team.name,
        }
      })
    } catch (error) {
      console.error(error)
      commit('Notify/setError', 'При получении списка задач произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
